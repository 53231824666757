import { render, staticRenderFns } from "./catering.vue?vue&type=template&id=4b62a76a&"
import script from "./catering.vue?vue&type=script&lang=js&"
export * from "./catering.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bahaaeddine/public_html/hmd/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4b62a76a')) {
      api.createRecord('4b62a76a', component.options)
    } else {
      api.reload('4b62a76a', component.options)
    }
    module.hot.accept("./catering.vue?vue&type=template&id=4b62a76a&", function () {
      api.rerender('4b62a76a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/dashboard/components/campDashboard/catering.vue"
export default component.exports