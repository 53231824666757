var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _vm.header
        ? _c("campOverviewDashboard", {
            attrs: { dataHeader: _vm.DataDashboard }
          })
        : _vm._e(),
      _c("hr"),
      _c(
        "div",
        { staticClass: "row p-4 text-center d-flex justify-content-between" },
        [
          _c("div", { staticClass: "col" }, [
            _c("img", {
              staticClass: "card-img img-fluid small-img",
              staticStyle: { cursor: "pointer" },
              attrs: { src: require("@/assets/images/dashboard/idea.png") },
              on: {
                click: function($event) {
                  _vm.reqShow = !_vm.reqShow
                }
              }
            }),
            _c("h3", [_vm._v("Requêtes")])
          ]),
          _c("div", { staticClass: "col" }, [
            _c("img", {
              staticClass: "card-img img-fluid small-img",
              staticStyle: { cursor: "pointer" },
              attrs: { src: require("@/assets/images/dashboard/room.png") },
              on: {
                click: function($event) {
                  _vm.hostShow = !_vm.hostShow
                }
              }
            }),
            _c("h3", [_vm._v("Hébergement")])
          ]),
          _c("div", { staticClass: "col" }, [
            _c("img", {
              staticClass: "card-img img-fluid small-img",
              staticStyle: { cursor: "pointer" },
              attrs: { src: require("@/assets/images/dashboard/catering.png") },
              on: {
                click: function($event) {
                  _vm.catShow = !_vm.catShow
                }
              }
            }),
            _c("h3", [_vm._v("Restauration")])
          ]),
          _c("div", { staticClass: "col" }, [
            _c("img", {
              staticClass: "card-img img-fluid small-img",
              staticStyle: { cursor: "pointer" },
              attrs: { src: require("@/assets/images/dashboard/gs.png") },
              on: {
                click: function($event) {
                  _vm.gsShow = !_vm.gsShow
                }
              }
            }),
            _c("h3", [_vm._v("Espace Vert")])
          ]),
          _c("div", { staticClass: "col" }, [
            _c("img", {
              staticClass: "card-img img-fluid small-img",
              staticStyle: { cursor: "pointer" },
              attrs: {
                src: require("@/assets/images/dashboard/extermination.png")
              },
              on: {
                click: function($event) {
                  _vm.opsShow = !_vm.opsShow
                }
              }
            }),
            _c("h3", [_vm._v("Ops 3D")])
          ])
        ]
      ),
      _vm.gsShow == true
        ? _c("campGsDashboard", { attrs: { campid: _vm.$route.params.campId } })
        : _vm._e(),
      _vm.opsShow == true
        ? _c("campOpsDashboard", {
            attrs: { campid: _vm.$route.params.campId }
          })
        : _vm._e(),
      _vm.catShow == true
        ? _c("campCatDashboard", {
            attrs: { campid: _vm.$route.params.campId }
          })
        : _vm._e(),
      _vm.hostShow == true
        ? _c("campHostingDashboard", {
            attrs: { campid: _vm.$route.params.campId }
          })
        : _vm._e(),
      _vm.reqShow == true
        ? _c("campReqDashboard", {
            attrs: { campid: _vm.$route.params.campId }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }