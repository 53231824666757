var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("Opération 3D")]),
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-md-3" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-12 col-lg-12" }, [
            _c("div", { staticClass: "card card-stats mb-4 mb-xl-0" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Date")]),
                      _c("VueCtkDateTimePicker", {
                        staticStyle: {
                          display: "inline-block",
                          "margin-bottom": "20px"
                        },
                        attrs: {
                          locale: "fr",
                          range: true,
                          formatted: "ll",
                          color: "#34495e",
                          "custom-shortcuts": _vm.customDateRangeShortcuts,
                          "only-date": true,
                          "auto-close": false
                        },
                        model: {
                          value: _vm.dates,
                          callback: function($$v) {
                            _vm.dates = $$v
                          },
                          expression: "dates"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "div",
          { staticClass: "card card-stats mb-4 mb-xl-0 f-h text-center" },
          [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col br-solid" }, [
                  _c(
                    "h5",
                    {
                      staticClass: "card-title text-uppercase text-muted mb-0"
                    },
                    [_vm._v(" Coût Mensuel Prévisionnel ")]
                  ),
                  _c(
                    "span",
                    { staticClass: "h4 font-weight-bold mb-0 text-danger" },
                    [
                      _vm._v(
                        _vm._s(
                          new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "DZD",
                            maximumSignificantDigits: 2
                          }).format(_vm.DashboardData.monthCost)
                        )
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "h5",
                    {
                      staticClass: "card-title text-uppercase text-muted mb-0"
                    },
                    [_vm._v(" Coût Mensuel Vérifié ")]
                  ),
                  _c(
                    "span",
                    { staticClass: "h4 font-weight-bold mb-0 text-success" },
                    [
                      _vm._v(
                        _vm._s(
                          new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "DZD",
                            maximumSignificantDigits: 2
                          }).format(_vm.DashboardData.monthCost)
                        )
                      )
                    ]
                  )
                ])
              ]),
              _c("hr"),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "h5",
                    {
                      staticClass: "card-title text-uppercase text-muted mb-0"
                    },
                    [_vm._v(" Total Services Demandés ")]
                  ),
                  _c("h4", { staticClass: "mt-4" }, [
                    _vm._v(_vm._s(_vm.DashboardData.services))
                  ])
                ]),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "h5",
                    {
                      staticClass: "card-title text-uppercase text-muted mb-0"
                    },
                    [_vm._v(" Total Fournitures Demandés ")]
                  ),
                  _c("h4", { staticClass: "mt-4" }, [
                    _vm._v(_vm._s(_vm.DashboardData.products))
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "col-md-3" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-12 col-lg-12 mt-2" }, [
            _c("div", { staticClass: "card card-stats mb-4 mb-xl-0" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "h5",
                      {
                        staticClass: "card-title text-uppercase text-muted mb-0"
                      },
                      [_vm._v(" ODS En cours ")]
                    ),
                    _c("span", { staticClass: "h2 font-weight-bold mb-0" }, [
                      _vm._v(_vm._s(_vm.DashboardData.inprogress))
                    ])
                  ])
                ]),
                _vm._m(0)
              ])
            ])
          ]),
          _c("div", { staticClass: "col-xl-12 col-lg-12 mt-2" }, [
            _c("div", { staticClass: "card card-stats mb-4 mb-xl-0" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "h5",
                      {
                        staticClass: "card-title text-uppercase text-muted mb-0"
                      },
                      [_vm._v(" ODS Clôturés ")]
                    ),
                    _c("span", { staticClass: "h2 font-weight-bold mb-0" }, [
                      _vm._v(_vm._s(_vm.DashboardData.finished))
                    ])
                  ])
                ]),
                _vm._m(1)
              ])
            ])
          ])
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "card p-2", attrs: { id: "chart" } },
      [
        _c("apexchart", {
          attrs: {
            type: "bar",
            height: "350",
            options: _vm.chartOptions,
            series: _vm.series
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-3 mb-0 text-muted text-sm" }, [
      _c("span", { staticClass: "text-success mr-2" }, [
        _c("i", { staticClass: "fa fa-arrow-up" }),
        _vm._v(" 3.48%")
      ]),
      _c("span", { staticClass: "text-nowrap" }, [_vm._v("Since last month")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-3 mb-0 text-muted text-sm" }, [
      _c("span", { staticClass: "text-success mr-2" }, [
        _c("i", { staticClass: "fa fa-arrow-up" }),
        _vm._v(" 3.48%")
      ]),
      _c("span", { staticClass: "text-nowrap" }, [_vm._v("Since last month")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }